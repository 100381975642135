<template>
  <div>
    <div class="ui-type-display-lg mb-3">
      {{ $t("role.orders.archive.title") }}
    </div>

    <CCard>
      <CCardBody>
        <form @submit.prevent="onSearch">
          <CRow class="align-items-end">
            <CCol>
              <CInput
                :label="$t('role.orders.archive.search.search')"
                :value.sync="filters.keyword"
                :placeholder="$t('role.orders.archive.search.placeholder')"
              >
                <template #prepend-content>
                  <font-awesome-icon icon="search" />
                </template>
              </CInput>
            </CCol>
            <CCol md="3">
              <UiDatePicker
                :label="$t('role.orders.archive.search.from')"
                :date.sync="filters.from"
                :clearButton="false"
                :readonly="true"
              />
            </CCol>
            <CCol md="3">
              <UiDatePicker
                :label="$t('role.orders.archive.search.until')"
                :date.sync="filters.to"
                :clearButton="false"
                :readonly="true"
              />
            </CCol>
            <CCol md="2">
              <CButton
                class="mb-3 mr-2"
                variant="outline"
                color="primary"
                block
                type="submit"
              >
                {{ $t("role.orders.archive.search.submitButton") }}
              </CButton>
            </CCol>
          </CRow>
          <div class="text-right">
            <strong
              >{{ $t("role.orders.archive.search.resultCount") }}:
              {{ resultsCount }}</strong
            >
          </div>
        </form>
      </CCardBody>
    </CCard>

    <div class="d-flex justify-content-center" v-if="loading">
      <CSpinner color="info" />
    </div>

    <div v-else-if="orders.length > 0">
      <MarketOrder
        v-for="order in orders"
        :order="order"
        :key="order.id"
        :messageButton="false"
        :showFamilyName="true"
        type="role"
      />

      <CPagination
        v-if="numPages > 1 && !loading"
        :active-page.sync="currentPage"
        :pages="numPages"
        sime="sm"
        responsive
      />
    </div>

    <div v-else>{{ $t("role.orders.archive.search.none") }}</div>

    <IncidentDetailsModal />
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall } from "../../../ds";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import { MarketOrder } from "../../user/partials";
import UiDatePicker from "../../../common/form/UiDatePicker";
import subMonths from "date-fns/subMonths";
import { addAndPredicateFilter } from "../../../helpers/common";
import IncidentDetailsModal from "../../share/IncidentDetailsModal";

export default {
  name: "UiMarketOrders",
  components: {
    MarketOrder,
    UiDatePicker,
    IncidentDetailsModal,
  },

  data() {
    return {
      filters: {
        keyword: "",
        from: subMonths(new Date(), 6),
        to: new Date(),
      },
      loading: false,
      orders: [],
      resultsCount: 0,
      numPages: 1,
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.getOrders();
  },

  watch: {
    currentPage: function () {
      this.getOrders();
    },
  },

  methods: {
    prepareQuery() {
      const { keyword, from, to } = this.filters;
      let predicate = null;
      if (from && to) {
        predicate = addAndPredicateFilter(
          predicate,
          "ddmi__time_limit",
          "greaterthan",
          from.toISOString()
        );

        predicate = addAndPredicateFilter(
          predicate,
          "ddmi__time_limit",
          "lessthan",
          to.toISOString()
        );
      }

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = [
        "id",
        "family__name",
        "market__role__name",
        "ddmi__island__role__name",
        "details__ddmp__producer__role__name",
        "details__ppm__lot__translations__name",
        "details__ppm__lot__productproducer__translations__name",
      ];

      if (keyword) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getOrders() {
      const self = this;
      const query = this.prepareQuery();
      const itemsPerPage = 10;
      self.loading = true;

      query.page(this.currentPage, itemsPerPage);
      ApiCall(
        GetDataManagerNew("role_order_role", [this.$store.state.role.id]),
        query,
        (response) => {
          self.resultsCount = response.actual.payload.Count;
          self.numPages = Math.ceil(self.resultsCount / itemsPerPage);
          self.orders = response.result;
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    onSearch() {
      this.getOrders();
    },
  },
};
</script>

<style scoped>
.card-body >>> .form-control[readonly] {
  background-color: #fff !important;
}
</style>
